import BaseController from './base_controller'
import { useClickOutside } from 'stimulus-use'

// Uses hidden template content attach a floating modal to the controller element
export default class FloatingModalController extends BaseController {
  static targets = ['template']

  static values = {
    position: String,
    floatingModalId: { type: String, default: 'floating_modal' }
  }

  connect () {
    super.connect()
    useClickOutside(this)

    this.floatingModal = document.getElementById(this.floatingModalIdValue)
  }

  toggle () {
    if (this.floatingModal.controllers.attachable.attachedToElement(this.element)) {
      this.floatingModal.controllers.attachable.detach()
    } else {
      this.floatingModal.innerHTML = this.templateTarget.innerHTML
      this.floatingModal.controllers.attachable.attach(this.element, false, this.positionValue)
    }
  }

  clickOutside (event) {
    if (event.target.closest(`#${this.floatingModalIdValue}`)) return // Don't close when clicking inside the modal

    this.floatingModal.controllers.attachable.detach()
  }
}
