import BaseController from './base_controller'

// Attach a floating element to another element
export default class AttachableController extends BaseController {
  attach (elementToAttach, matchWidth, position = 'bottomStart') {
    this.attachedElement = elementToAttach
    this.matchWidth = matchWidth
    this.position = position
    this.element.hidden = false

    this.reposition()
  }

  detach () {
    this.attachedElement = null
    this.element.hidden = true
  }

  // Repositions element next to attachedElement
  reposition () {
    if (!this.attachedElement) return

    const attachedRect = this.attachedElement.getBoundingClientRect()
    const elementRect = this.element.getBoundingClientRect()

    switch (this.position) {
    case 'bottomEnd':
      this.element.style.top = attachedRect.bottom + 'px'
      this.element.style.bottom = 'unset'
      this.element.style.left = (attachedRect.right - elementRect.width) + 'px'
      this.element.style.right = 'unset'
      break
    default: // bottomStart
      this.element.style.top = attachedRect.bottom + 'px'
      this.element.style.bottom = 'unset'
      this.element.style.left = attachedRect.left + 'px'
      this.element.style.right = 'unset'
      break
    }

    if (this.matchWidth) {
      this.element.style.width = attachedRect.width + 'px'
    }
  }

  attachedToElement (testElement) {
    return this.attachedElement === testElement
  }
}
